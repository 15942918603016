$(() => {

    /**
     * -----------------------------------------------------------------------------
     * Formulário de comentário
     * -----------------------------------------------------------------------------
     */
     $('#commentForm').on('submit', (e) => {
        e.stopPropagation()
        e.preventDefault()

        const form = $(e.currentTarget)
        const url = form.attr('action')

        $.ajax({
            url: url,
            method: 'post',
            data: form.serialize(),
            success: (data) => {

                var html = '<li>'+ data +'</li>'

                $('#listComment').append(html)

                replyButton()
                cleanReply()

                $('#field_comment_content').val('')
                $('#listEmpty').remove()
            }
        })
    })

    $('#removeReply').on('click', (e) => {
        e.preventDefault()
        e.stopPropagation()

        cleanReply()
    })

    function replyButton()
    {
        const reply = $('[href="#reply"]')
        reply.off()

        reply.on('click', (e) => {
            const target =  $('#replyArea')
            const comment = $(e.currentTarget).parents('.e-comment')
            const id = comment.attr('data-id')
            const content = comment.find('.comment').text()

            e.preventDefault()
            e.stopPropagation()

            target.addClass('active')
            target.find('a').attr('href', '#comment-'+ id).text(content)

            $('#idReply').val(id)
            $('#field_comment_content').trigger('focus')
        })
    }

    function cleanReply()
    {
        $('#idReply').val('')
        $('#replyArea').removeClass('active')
            .find('a')
            .attr('href', '')
            .text('')
    }

    replyButton()
    //-- formulario-de-comentario ----------------------------------------------

})
