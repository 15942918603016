import io from 'socket.io-client';

const widget = $('#widgetChat');
const user = parseInt($('#me').attr('data-me'));

var page = 1;
var arr_open = (widget.attr('data-open')) ? JSON.parse(widget.attr('data-open')) : [];
var start = true;

/**
 * -----------------------------------------------------------------------------
 * Pré inicializações
 * -----------------------------------------------------------------------------
 */
alertChat()

/**
 * -----------------------------------------------------------------------------
 * Preparando Socket.io
 * -----------------------------------------------------------------------------
 */
const myself = $('#widgetChat').data('myself');
const server = 'https://rest.allyotta.com/';
const socket = io.connect(server, {
    transports: ['websocket']
});

socket.emit('connection')
socket.on('on', (data) => console.log('Connection:', data))
socket.on('err', (data) => console.log(data))
socket.on('debug', (data) =>  console.log(data))

socket.on('alert_'+ user, (data) => {
    alertChat(data)
})

/**
 * -----------------------------------------------------------------------------
 * Sinalizações de Alerta
 * -----------------------------------------------------------------------------
 *
 * @param {object} data
 */
function alertChat(data = false)
{
    const alert = $('#alertChat')

    if(data) {

        const room = $('#linkRoom'+ data.room)

        if(data.user != user) {

            room.addClass('unread')
            alert.addClass('unread')
            playAlert()

        } else {

            room.removeClass('unread')
        }

        if(!$('.e-link.unread').length) {

            alert.removeClass('unread')
        }

        if(data.message) room.find('.e-excerpt').html(data.message)
    }

    else if($('.e-link.unread').length) {

        alert.addClass('unread')
    }
}

/**
 * -----------------------------------------------------------------------------
 * Criar lista
 * -----------------------------------------------------------------------------
 *
 * Cria um objeto com os parâmetros para enviar ao servidor.
 *
 * @param {int} room
 * @param {int} user
 * @param {int} pg
 * @param {boolean} fav
 * @returns object
 */
function createList(room, pg = 1, fav = false)
{
    return {
        me: user,
        room: room,
        pg: pg,
        bookmark: fav
    }
}

/**
 * -----------------------------------------------------------------------------
 * Ações de scroll
 * -----------------------------------------------------------------------------
 *
 * Vai para o final da janela de mensagens automaticamente.
 */
function scrollHeight(list)
{
    var listMessage = $('#listMessage'+ list.room)
    var div = document.getElementById('listMessage'+ list.room)

    listMessage.scrollTop(listMessage.prop('scrollHeight'))

    listMessage.off()
    listMessage.on('scroll', (e) => {
        var position = $(e.currentTarget).scrollTop()

        if(position == 0) {
            page ++
            list.pg = list.pg + 1
            socket.emit('list_message', list)
        }

        if (div.scrollHeight - div.scrollTop === div.clientHeight) {
            socket.emit('read', {
                room: list.room,
                me: user
            })
        }

    })

}

/**
 * -----------------------------------------------------------------------------
 * Identifica links
 * -----------------------------------------------------------------------------
 *
 * Varre a mensagem em busca de links e formata o HTML para linkar para outra
 * página.
 *
 * @param {string} text
 * @returns
 */
function identifyLink(text) {
    var urlRegex = /(?:(?:https?|ftp|file):\/\/|www\.|ftp\.)(?:\([-A-Z0-9+&@#\/%=~_|$?!:,.]*\)|[-A-Z0-9+&@#\/%=~_|$?!:,.])*(?:\([-A-Z0-9+&@#\/%=~_|$?!:,.]*\)|[A-Z0-9+&@#\/%=~_|$])/igm

    return text.replace(urlRegex, (url) => {
        var link = ''
        link = url.replace('https://', '')
        link = link.replace('http://', '')
        return '<a href="//' + link + '" target="blank">' + url + '</a>'
    })
}

function formatText(text) {
    return text.replaceAll("\n",'<br>')
}

/**
 * -----------------------------------------------------------------------------
 * Formatar data
 * -----------------------------------------------------------------------------
 *
 * Exibe a data da mensagem, caso seja hoje, exibe a hora.
 *
 * @param {int} date
 * @param {boolean} today
 * @returns string
 */
function formatDate(date, today = true){
    var date = new Date(date)

    if(today) {
        var hour = date.getHours().toString(),
            hour = (hour.length == 1) ? '0'+hour : hour,
            min = date.getMinutes().toString(),
            min = (min.length == 1) ? '0'+min : min
        return hour +'h'+ min
    }

    var day  = date.getDate().toString(),
        day = (day.length == 1) ? '0'+day : day,
        month  = (date.getMonth()+1).toString(),
        month = (month.length == 1) ? '0'+month : month,
        year = date.getFullYear()
    return day +'/'+ month +'/'+ year
}

/**
 * -----------------------------------------------------------------------------
 * Tocar som
 * -----------------------------------------------------------------------------
 */
function playAlert()
{
    const audio = document.getElementById('chatAlert')

    var playPromise = audio.play();
    if(audio) audio.play()
    console.log('Ding! Dong!')
}

/**
 * -----------------------------------------------------------------------------
 * Resposta
 * -----------------------------------------------------------------------------
 *
 * Permite responder a uma mensagem enviada anteriormente.
 *
 * @param {string} message
 */
function replyMessage(message, card) {
    const id = message.attr('data-id')
    const msg = message.find('.m-content').text()
    console.log(msg)

    card.find('[name="reply"]').val(id)
    card.find('.message-link').addClass('active')
    card.find('.message-link .txt').text(msg)

    card.find('[name="message"]').trigger('focus')
}

/**
 * -----------------------------------------------------------------------------
 * Anexar
 * -----------------------------------------------------------------------------
 *
 * Permite anexar um arquivo à mensagem.
 *
 * @param {object} att
 */
function attachment(att, card) {

    const link = card.find('.message-link')
    const expand = card.find('.btn-chat-expand')
    var html = ''

    //att = att[0]

    link.addClass('active')
    link.attr('data-object', att.src)

    if(1 == 0
        || att.name.endsWith('.jpg')
        || att.name.endsWith('.jpeg')
        || att.name.endsWith('.png')
    ) {

        html+= '<div class="row align-items-center">'
        html+= '<div class="col-auto">'
            html+= '<img class="img-thumbnail" src="'+ att.src +'" alt=""></img>'
        html+= '</div><!--/.col -->'
        html+= '<div class="col">'
            html+= '<span>'+ att.name +'</span>'
        html+= '</div><!--/.col -->'
        html+= '</div><!--/.row -->'

    } else {

        html+= '<div class="row align-items-center">'
        html+= '<div class="col-auto">'
            html+= '<span class="img-thumbnail fa-solid fa-fw fa-file-upload"></span> '
        html+= '</div><!--/.col -->'
        html+= '<div class="col">'
            html+= '<span>'+ att.name +'</span>'
        html+= '</div><!--/.col -->'
        html+= '</div><!--/.row -->'
    }

    if(expand) expand.trigger('click')
    link.find('.txt').html(html)
}

/**
 * -----------------------------------------------------------------------------
 * Vídeo do YouTube
 * -----------------------------------------------------------------------------
 *
 * Varre a string em busca de links do youtube e exibe o vídeo incorporado
 * dentro da mensagem.
 *
 * @param {string} url
 * @returns string
 */
function YouTubeGetID(url) {
    var ID = ''
    var link = ''

    url = url.replace(/(>|<)/gi,'').split(/(vi\/|v=|\/v\/|youtu\.be\/|\/embed\/)/)
    if(url[2] !== undefined) {
        ID = url[2].split(/[^0-9a-z_\-]/i)
        ID = ID[0]

        link+= '<div class="ratio ratio-16x9">'
            link+= '<iframe src="https://www.youtube.com/embed/'+ ID +'?rel=0&showinfo=0&disablekb=1&modestbranding=1" allow="clipboard-writeencrypted-mediagyroscopepicture-in-picture" allowfullscreen></iframe>'
        link+= '</div><!--/.ratio -->'
        link+= '<hr>'
    }

    return link
}

/**
 * -----------------------------------------------------------------------------
 * Criar mensagem
 * -----------------------------------------------------------------------------
 *
 * Cria a mensagem para exibir no container.
 *
 * @param {object} item
 * @returns
 */
function createMessage(item) {

    if(1 == 0
        || item._id == 739
        || item._id == 740
        || item._id == 741
        || item._id == 742
    ) { return '' }

    var html = '',
    picture = '',
    sequence = '',
    fav = item.bookmark.includes(user),
    icon = (fav) ? 'fas' : 'far',
    me = (item.me) ? 'me' : '',
    now = new Date(),
    date = new Date(item.create),
    val = new Date(item.create),
    content = '',
    day = formatDate(date)

    val = val.setMonth(val.getMonth()+3)

    if((date.getDay() !== now.getDay())) {
        day = formatDate(date, false)
    }

    if(item.first) picture = ' with-picture'
    if(item.sequence) sequence = ' sequence'

    html+= '<div id="message_'+ item._id +'" class="e-message ' + me + picture + sequence +'" data-id="'+ item._id +'" data-user-id="'+ item.sender._id +'" tabindex="0">'
        if(picture) {
            html+= '<div class="e-thumb" style="background-image: url(\''+ item.sender.image +'\')">'
                html+= '<img class="sr-only" src="'+ item.sender.image +'" alt="">'
            html+= '</div><!--/.e-thumb -->'
        }

        html+= '<div class="message">'

        if(item.reply) {
            html+= '<a href="#message_'+ item.reply._id +'" class="m-link" target="_blank">'
                html+= item.reply.content
            html+= '</a><!--/.m-reply -->'
        }


        html+= '<span class="m-date">'+ day +'</span>'
        html+= '<div class="m-action">'

            if(item.attachment) {
                html+= '<a class="btn" href="'+ item.url +'" target="_blank">'
                    html+= '<span class="fa-solid fa-fw fa-download"></span>'
                    html+= '<span class="sr-only">Baixar</span>'
                html+= '</a><!--/.btn -->'
            }

            html+= '<button class="btn" type="button" data-action="favorite" aria-active="'+ fav +'">'
                html+= '<span class="'+ icon +' fa-fw fa-star"></span>'
                html+= '<span class="sr-only">Favorito</span>'
            html+= '</button><!--/.btn -->'

            html+= '<button class="btn" type="button" data-action="reply">'
                html+= '<span class="fa-solid fa-fw fa-reply"></span>'
                html+= '<span class="sr-only">Responder</span>'
            html+= '</button><!--/.btn -->'

            if(!me) {
                html+= '<button type="button" class="btn" data-action="private" data-user="'+ item.sender._id +'" data-me="'+ item.sender._id +'">'
                    html+= '<span class="fa-solid fa-fw fa-user"></span>'
                    html+= '<span class="sr-only">Ver perfil</span>'
                html+= '</button><!--/.btn -->'
            }
        html+= '</div><!--/.m-action -->'

        if(1 == 1
            && item.attachment
            && val < now
        ) {

            content+= '<small class="m-erased">Esta mensagem não está mais disponível</small>'

        } else if(item.attachment) {

            if(!item.url) {
                item.url = item.content
                item.content = ''
            }
            if(1 == 0
                || item.url.endsWith('.jpg')
                || item.url.endsWith('.jpeg')
                || item.url.endsWith('.png')
            ) {
                content+= '<button class="img-thumbnail m-attachment-image" type="button" data-file="'+ item.url +'">'
                    content+= '<span class="fa-solid fa-images"></span>'
                    content+= '<img src="'+ item.url +'">'
                content+= '</button>'

                if(item.content) {
                    content+= '<hr>'
                    content+= item.content
                }
            } else if(1 == 0
                || item.url.endsWith('.mp4')
                || item.url.endsWith('.webm')
                || item.url.endsWith('.WebM')
                || item.url.endsWith('.ogg')
            ) {
                content+= '<div class="ratio ratio-16x9">'
                content+= '<video controls>'
                    content+= '<source src="'+ item.url +'">'
                content+= '</video>'
                content+= '</div><!--/.radio -->'

                if(item.content) {
                    content+= '<hr>'
                    content+= item.content
                }
            } else {

                var icon = 'file-download'

                if(item.url.endsWith('.pdf')) {
                    icon = 'file-pdf'
                }
                if(item.url.endsWith('.doc') || item.url.endsWith('.docx')) {
                    icon = 'file-word'
                } else if(item.url.endsWith('.ppt') || item.url.endsWith('.pptx')) {
                    icon = 'file-powerpoint'
                } else if(item.url.endsWith('.xls') || item.url.endsWith('.xlsx')) {
                    icon = 'file-excel'
                }

                content+= '<a class="m-attachment" href="'+ item.url +'">'
                content+= '<div class="row align-items-center">'
                    content+= '<div class="col-auto">'
                        item.content = item.content.split("/")
                        item.content = item.content[item.content.length -1]
                        content+= '<span class="img-thumbnail fa-solidfa-'+ icon +'"></span>'
                    content+= '</div><!--/.col -->'
                    content+= '<div class="col">'
                        content+= '<span class="txt">'+ item.url +'</span>'
                    content+= '</div><!--/.col -->'
                content+= '</div><!--/.row -->'
                content+= '</a>'

                if(item.content) {
                    content+= '<hr>'
                    content+= formatText(item.content)
                }
            }

        } else if(YouTubeGetID(item.content)) {
            content = YouTubeGetID(item.content) +"\n"+ formatText(identifyLink(item.content))
        } else {
            content+= formatText(identifyLink(item.content))
        }

        html+= (me || item.sequence) ? '' : '<a href="'+ item.sender.link +'" class="m-sender">'+ item.sender.name +':</a> '
        html+= '<span class="m-content">'+ content +'</span>'

    html+= '</div><!--/.message -->'
    html+= '</div><!--/.e-message -->'

    return html
}

/**
 * -----------------------------------------------------------------------------
 * Ativar sala
 * -----------------------------------------------------------------------------
 *
 * Fecha a janela e remove o cookie.
 */
 function activeRoom(list)
{
    const room = $('#room'+ list.room)
    const listMessage = room.find('.card-body')

    const btnClose = room.find('.btn-chat-close')
    const btnExpand = room.find('.btn-chat-expand')
    const btnCompress = room.find('.btn-chat-compress')
    const btnUpload = room.find('[data-toggle="chatUpload"]')

    const form = room.find('.message-form')
    const inputFile = room.find('[name="file"]')
    const inputMessage = room.find('[name="message"]')
    const inputReply = room.find('[name="reply"]')
    const messageLink = room.find('.message-link')

    const arr_messager = Object.values(document.getElementsByClassName('messager'))

    arr_messager.forEach(messager => {
        messager.addEventListener('keydown', (e) => {
            e = e.currentTarget

            setTimeout(() => {
                e.style.cssText = 'height:auto padding:0'
                e.style.cssText = 'height:' + e.scrollHeight + 'px'
            }, 0)

        })
    })

    // Botão fechar
    btnClose.off()
    btnClose.on('click', (e) => {
        const card = $(e.currentTarget).parents('.card')
        const room = card.attr('data-room')
        const route = '/chat/room/close/' + room

        card.remove()

        $.ajax({
            url: route,
            method: 'PUT',
            context: document.body
        })
    })

    // Botão expandir
    btnExpand.off()
    btnExpand.on('click', (e) => {

        let windowHeight = $('body').height();
        console.log(windowHeight);

        $(e.currentTarget).parents('.card').css('max-height', windowHeight +'px').addClass('expand');
    })

    // Botão comprimir
    btnCompress.off()
    btnCompress.on('click', (e) => {
        $(e.currentTarget).parents('.card').removeClass('expand')
    })

    // Botão fazer upload
    btnUpload.off()
    btnUpload.on('click', (e) => {
        var container = $(e.currentTarget).parents('.card')
        var upload = container.find('[name="file"]')

        upload.trigger('click')
    })

    // Fazer upload do arquivo
    inputFile.off()
    inputFile.on('change', (e) => {
        const route = '/chat/upload';
        const card = $(e.currentTarget).parents('.card')
        const file = $(e.currentTarget)[0].files[0];

        if(e.target.files.length > 0) {

            var form_data = new FormData()
            form_data.append('file', file, file.name)

            $.ajax({
                url: route,
                method:"POST",
                data: form_data,
                contentType: false,
                processData: false,

                success: (data) => {

                    data = JSON.parse(data)
                    console.log('Success:', data)

                    attachment(data, card)
                }
            })
            .fail((data) => {

                console.log('Erro:', data)
                return false
            })
        }
    })

    // Enviar ao apertar enter
    inputMessage.off()
    inputMessage.on('keypress', e => {
        if(e.which == 13 && !e.shiftKey) {
            $(e.currentTarget).closest('form').trigger('submit')
            e.preventDefault()
        }
    })

    // Enviar mensagem
    form.off()
    form.on('submit', e => {
        e.preventDefault()
        e.stopPropagation()

        var reply = room.find('[name="reply"]').val()
        var message = room.find('[name="message"]').val()
        var file = room.find('.message-link').attr('data-object')

        if(!message && !file) return

        var data = {
            room: list.room,
            sender: user,
            content: message
        }

        if(file) {
            data.url = file
            data.attachment = true
        }

        if(reply) data.reply = reply

        var send = {
            me: user,
            body: data
        }

        socket.emit('create_message', send)

        inputMessage.val('')
        inputFile.val('')
        inputReply.val('')

        messageLink.attr('data-object', '')
        messageLink.removeClass('active')
        messageLink.find('.text').text('')
    })

    if(!start && arr_open.includes(list.room)) return

    arr_open.push(list.room)

    socket.on('message_' + list.room, (data) => {

        const last = listMessage.find('.e-message:last-child').attr('data-user-id')

        data.first = (last != data.sender._id)
        data.sequence = (last == data.sender._id)
        data.me = (data.sender._id == user)

        listMessage.append(createMessage(data))

        if(document.hasFocus()) scrollHeight(list)

        activeMessage(page, list)
    })

    // finish him
    start = false
}

/**
 * -----------------------------------------------------------------------------
 * Ativar mensagens
 * -----------------------------------------------------------------------------
 *
 * Configura os botões de ação possíveis para a mensagem.
 *
 * @param {object} page
 * @param {object} list
 */
function activeMessage(page, list) {
    const room = $('#room'+ list.room)
    const btnFavorite = room.find('[data-action="favorite"]')
    const btnReply = room.find('[data-action="reply"]')
    const btnPrivate = room.find('[data-action="private"]')
    const image = room.find('.m-attachment-image')
    const message = room.find('.message')
    const link = room.find('[data-toggle="message-link-cancel"]')
    const anchor = room.find('a[href^="#"]')

    btnFavorite.off()
    btnFavorite.on('click', (e) => {

        const fav = $(e.currentTarget)
        var active = (fav.attr('aria-active') === 'true')

        var aClass = (active === "true") ? 'far' : 'fas'
        var rClass = (active === "true") ? 'fas' : 'far'
        const id = $(e.currentTarget).parents('.e-message').attr('data-id')

        socket.emit('fav_message', {
            me: user,
            message: id,
            active: active
        })

        $(e.currentTarget).find('.fa-star').addClass(aClass)
        $(e.currentTarget).find('.fa-star').removeClass(rClass)
        $(e.currentTarget).attr('aria-active', (active != 'true'))
    })

    btnReply.off()
    btnReply.on('click', (e) => {
        const card = $(e.currentTarget).parents('.card')
        const message = $(e.currentTarget).parents('.e-message')

        replyMessage(message, card)
    })

    btnPrivate.off();
    btnPrivate.on('click', function(e) {

        const btn = $(this);
        const me = btn.attr('data-me');
        const user_id = btn.attr('data-id');

        $.ajax({
            url: server + 'v1/rooms',
            method: 'PUT',
            crossDomain: true,
            dataType: 'json',
            beforeSend: (request) => {
                request.setRequestHeader('me', '1');
              },
            data: JSON.stringify({
                id: user_id,
                type: 'user',
            }),

        }).done((data) => {
            console.log('Abrir sala:', data);
        });
    });

    image.off()
    image.on('click', (e) => {
        e.stopPropagation()
        e.preventDefault()

        var src = $(e.currentTarget).attr('data-file')

        $('#chatModal .modal-body').html('<img src="'+ src +'" alt="">')
        $('#chatModal .modal-footer .btn-download').attr('href', src)

        var chatModal = document.getElementById('chatModal')
        if(chatModal) {
            chatModal = new bootstrap.Modal(chatModal, {})
            chatModal.show()
        }
    })

    message.off()
    message.on('dblclick', (e) => {
        const card = $(e.currentTarget).parents('.card')

        replyMessage($(e.currentTarget), card)
    })

    link.off()
    link.on('click', (e) => {
        e.stopPropagation()
        e.preventDefault()

        const card = $(e.currentTarget).parents('.card')

        card.find('.message-reply').val('')
        card.find('[name="file"]').val('')
        card.find('.message-link').removeClass('active')
        card.find('.message-link .text').text('')
    })

    anchor.off()
    anchor.on('click', (e) => {
        e.preventDefault()
        e.stopPropagation()

        const target = $(e.currentTarget).attr('href')
        const listMessage = $(e.currentTarget).parents('.card-body')
        page = check(target, page, list)

        function check(target, page) {
            var count = listMessage.find(target).length

            if(!count) {
                listMessage.scrollTop(0)
                setTimeout(check, 50, target, page, list)
            }

            $(target).trigger('focus')
            return page
        }
    })
}

/**
 * -----------------------------------------------------------------------------
 * Abrir sala
 * -----------------------------------------------------------------------------
 *
 * Abre a sala na página e cria um cookie.
 */

function openRoom(id) {
    const route = '/chat/room/' + id

    $.ajax({
        url: route,
        method: 'PUT',
        context: document.body
    }).done((data) => {
        const list = createList(id)

        if($('#room'+ id).length) return

        $('#widgetChat .w-content').append(data)

        socket.emit('list_message', list)
        socket.on('room_'+ id +'_me_'+ user, (data) => {
            appendMessage(data, list)
        })

        activeRoom(list)
    })
}


$('[data-toggle="openRoom"]').on('click', (e) => {
    const room = $(e.currentTarget).attr('data-id')
    openRoom(room)
})

arr_open.forEach((room) => { openRoom(room) })

/**
 * -----------------------------------------------------------------------------
 * Inserir novas mensagens
 * -----------------------------------------------------------------------------
 */
function appendMessage(data, list) {

    var html = ''
    var target = '#listMessage'+ list.room
    var div = $(target)

    if(JSON.stringify(data) !== JSON.stringify({})) {

        if(!data.docs.length) return
        var last = $(target +' .e-message:last-child').attr('data-user-id')

        data.docs.forEach((item) => {

            if(!item.sender) return;

            item.first = (last != item.sender._id)
            item.sequence = (last == item.sender._id)

            html += createMessage(item)
            last = item.sender._id
        })

        var first = $(target +' .e-message:first-child')
        div.prepend(html)

        if(!data.scroll && first) {

            div.animate({
                scrollTop: first.offset().top - 80
            }, 0)
        }

        if(data.scroll) scrollHeight(list)
        activeMessage(page, list)
    }
}
