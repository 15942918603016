$(() => {

    /**
     * Remover filtro
     */
    $('[data-trigger="filterRemove"]').on('click', function(e)
    {
        e.stopPropagation();
        e.preventDefault();

        let url = '/rest/filter';
        const id = $(this).data('id');

        if(typeof id !== 'undefined') url+= '/'+ id;

        $.ajax({
            type: 'PUT',
            url: url,

            success: () => {
                location.reload(true);
            }
        });
    });

    /**
     * Fazer logout no sistema
     */
    $('[data-trigger="userLogout"]').on('click', function(e)
    {
        e.stopPropagation();
        e.preventDefault();

        $.ajax({
            url: '/rest/user/logout',
            method: 'PUT',

            success: () => {
                location.reload(true);
            }
        });
    });

    /**
     * Assumir perfil do usuário
     */
    $('[data-trigger="userTake"]').on('click', function(e)
    {
        e.stopPropagation();
        e.preventDefault();

        const id = $(this).data('id');

        $.ajax({
            url: '/rest/user/take/'+ id,
            method: 'PUT',

            success: () => {
                location.reload(true);
            }
        });
    });

    /**
     * Retornar ao perfil original
     */
    $('[data-trigger="userBack"]').on('click', function(e)
    {
        e.stopPropagation();
        e.preventDefault();

        $.ajax({
            url: '/rest/user/back',
            method: 'PUT',

            success: () => {
                location.reload()
            }
        });
    });

})
