$(() => {

    /** Alert */
    var alertModal = document.getElementById('alertModal');
    if(alertModal) {
        alertModal = new bootstrap.Modal(alertModal, {});
        alertModal.show();
    }

    /** File */
    var fileModal = document.getElementById('fileModal');
    if(fileModal) {
        $('[data-toggle="show-file"]').on('click', (e) => {
            //e.stopPropagation()
            //e.preventDefault()

            const container = $('#fileModal .ratio')
            const iframe = $('#fileModal .modal-iframe')
            const message = $('#fileModal .modal-message')
            const err = $(e.currentTarget).data('error')
            let file = $(e.currentTarget).attr('href')

            if(!file) return false

            if(file.includes('.pdf')) {
                iframe.attr('src', file)
                container.css('display','block')
                message.html('')
            }

            else if (1 == 0
                || file.includes('.ppt')
                || file.includes('.pptx')
                || file.includes('.doc')
                || file.includes('.docx')
                || file.includes('.xls')
                || file.includes('.xlsx')
            ) {
                file = 'https://view.officeapps.live.com/op/embed.aspx?src='+ file
                iframe.attr('src', file)
                container.css('display','block')
                message.html('')
            }

            else if (1 == 0
                || file.includes('.jpg')
                || file.includes('.jpeg')
                || file.includes('.png')
            ) {
                iframe.attr('src', file)
                container.css('display','block')
                message.html('')
            }

            else {
                iframe.attr('src', '')
                container.css('display','none')
                message.html('<div class="alert alert-warning">'+ err +'</div>')
            }

            //fileModal = new bootstrap.Modal(fileModal, {})
            //fileModal.show()
        })
    }

    /** Gallery Crousel */
    $('.e-link-gallery').on('click', (e) => {
        let galleryCarousel = document.querySelector('#galleryCarousel')
        let carousel = new bootstrap.Carousel(galleryCarousel)
        let btn = $(e.currentTarget)
        let item = btn.parent().data('item')

        carousel.to(item)
    })

})
