$(() => {

    var checkout = $('#sidebarCheckout');

    if(checkout.length) {

        $('[name="checkout_payment_mode"]').on('change', function()
        {
            const pix = $('#field_checkout_payment_pix').is(':checked');

            if(pix) {
                $('.tab-pane [type="text"]').attr('disabled', 'disabled');
                $('.tab-pane [type="select"]').attr('disabled', 'disabled');
            } else {
                $('.tab-pane [type="text"]').removeAttr('disabled');
                $('.tab-pane [type="select"]').removeAttr('disabled');
            }

            $.ajax({
                method: 'POST',
                url: '/rest/checkout/sidebar',
                data: {
                    pix: pix,
                },

                success: (view) => {
                    checkout.html(view);
                },

                error: (data) => {
                    console.log(data);
                }
            });
        })
    }

    /** Adicionar item ao carrinho de compras */
    $('[data-trigger="cartAdd"]').on('click', function(e)
    {
        e.stopPropagation();
        e.preventDefault();

        let btn = $(this);

        $.ajax({
            method: 'POST',
            url: '/rest/cart/add',

            data: {
                id: btn.data('id'),
                type: btn.data('type'),
                redirect: Boolean(btn.data('redirect'))
            },

            success: (redirect) => {
                if(redirect === 'false') location.reload(true);
                else location.href = redirect;
            }
        });
    });

    /** Remover item do carrinho de compras */
    $('[data-trigger="cartRemove"]').on('click', function(e)
    {
        e.stopPropagation();
        e.preventDefault();

        $.ajax({
            method: 'POST',
            url: '/rest/cart/remove',

            data: {
                id: $(this).data('id'),
            },

            success: () => {
                location.reload(true);
            }
        })
    })

})
